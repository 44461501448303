.item-top
    display: block
    margin-top: 20px
    text-decoration: none
    color: #3C4043
    font-size: 14px
    padding-top: 1px
    line-height: 1.3
    word-break: break-word

    h3
        margin: 5px 0
        font-size: 20px
        font-weight: 400
        line-height: 1.3
        color: #1a0dab

        .solved
            color: green
            font-weight: 450

        &:hover
            text-decoration: underline

.item-bottom
    p
        margin: 0
        color: #3C4043
        font-size: .8rem

    .badges-row-container
        margin-top: 5px

    .badge-category-bg
        flex: 0 0 auto
        width: 10px
        height: 10px
        margin-right: 2px
        display: inline-block

    .from
        font-size: .875rem
        padding: 2px

    .badge
        padding: 2px
        border-radius: 5px
        font-size: .1rem
        background-color: red

    .score
        display: inline-block
        margin-left: 20px
        font-size: .8rem