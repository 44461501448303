
$primary-color: #1a73e8
$secondary-color: #4caf50
$border-color: #e0e0e0
$shadow-color: rgba(0, 0, 0, 0.1)

.ai-answer-card
  border: 1px solid $border-color
  border-radius: 8px
  padding: 16px
  background-color: #fff
  box-shadow: 0 2px 8px $shadow-color
  font-family: Arial, sans-serif
  width: 97%

  .header
    display: flex
    flex-wrap: wrap
    align-items: center
    margin-bottom: 8px

    .ai-icon-title
      flex: 0 0 auto
      display: flex
      align-items: center

      .ai-icon
        width: 27px
        margin-right: 7px

      .title
        font-weight: bold
        font-size: 16px

    .beta-warning
      flex: 1 1 100%
      font-size: 12px
      color: #696969
      margin: 6px 0 0 0

  .content
    margin-bottom: 16px

  .question
    font-size: 1.1rem
    font-weight: bold
    margin-bottom: 8px

  .answer
    position: relative
    transition: max-height 0.3s ease-in-out
    overflow: hidden

    &.is-collapsed
      max-height: 150px // 设置折叠时的最大高度

    .collapse-mask
      position: absolute
      bottom: 0
      left: 0
      right: 0
      height: 70px
      background: linear-gradient(transparent, white)

    :deep(a)
      color: $primary-color
      text-decoration: none
      position: relative

    :deep(ul), :deep(ol)
      list-style: disc
      padding-left: 1rem

    :deep(h1), :deep(h2), :deep(h3), :deep(h4), :deep(h5), :deep(h6)
      font-size: 1rem
      font-weight: bold

    :deep(pre)
      white-space: pre-wrap
      word-wrap: break-word
      overflow-x: auto

    :deep(img)
      width: 100%

    :deep(table)
      border-collapse: collapse
      margin-bottom: 8px
      width: 100%

    :deep(th), :deep(td)
      border: 1px solid #e0e0e0
      padding: 8px
      text-align: left
      white-space: normal

  .collapse-button
    display: flex
    align-items: center
    justify-content: center
    color: $primary-color
    cursor: pointer
    padding: 5px
    margin-top: 2px
    font-size: .8rem

    &:hover
      background-color: rgba(26, 115, 232, 0.1)
      border-radius: 4px

  .actions
    display: flex
    justify-content: flex-start
