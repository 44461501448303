
#search-index-wrapper
  margin: 80px 0 10px 80px
  max-width: 1200px
  box-sizing: border-box
  background: #fff

.search-container
  position: absolute
  top: 90px
  box-sizing: border-box
  background-color: #fff
  border: 1px solid #dfe1e5
  box-shadow: none
  max-width: 700px
  width: 100%
  border-radius: 24px
  z-index: 200
  padding: 5px 0

  &:hover
    box-shadow: 0px 2px 8px 1px rgb(176 172 180 / 24%)

  &:focus-within
    box-shadow: 0px 2px 8px 1px rgb(176 172 180 / 24%)

  .top
    height: 42px

  .q-icon
    margin: 0 0 0 10px
    line-height: 38px
    height: 42px
    width: 42px
    background: transparent
    border: none
    cursor: pointer
    font-size: 1.3rem
    user-select: none
    outline: none

  .input-containner
    display: flex
    flex: 1
    flex-wrap: wrap

  input
    background-color: transparent
    border: none
    margin: 0
    padding: 0
    color: rgba(0,0,0,.87)
    word-wrap: break-word
    outline: none
    display: flex
    flex: 100%
    -webkit-tap-highlight-color: transparent
    margin-top: 3px
    height: 34px
    font-size: 16px

    &::placeholder
      font-size: .875rem
      color: #bbb

  .suggestions
    .divider
      margin: 0 10px
      border-top: 1px solid #d0d0d0

    ul
      list-style: none
      padding: 0
      margin: 5px 0

      a
        text-decoration: none
        color: #000
        cursor: pointer
        font-size: 1rem

      li
        padding-left: 20px
        line-height: 2rem

        &:hover
          background-color: #fff0f0
          border-left: 2px solid #be1d32

.search-types-wrapper
  margin-top: 75px
  color: #666
  font-size: 13px
  outline: none
  position: relative
  z-index: 102
  overflow: hidden
  overflow-x: auto

.search-types-container
  display: flex
  list-style: none
  padding: 0
  margin: 0

  .item
    color: #000
    padding: 10px 10px 10px 0
    margin-left: 13px
    cursor: pointer
    white-space: nowrap
    text-decoration: none

    .q-icon
      margin-right: 4px

    &.router-link-exact-active
      border-bottom: 2px solid #be1d32
      color: #be1d32

.result-container
  max-width: 700px
  min-height: 500px
  flex: 1 1 auto
  margin-top: 10px
  padding: 10px 0 0 10px

  .tidb-ai-card
    margin: 0 0 16px 0

.result-right-side
  margin-left: 100px

@media screen and (max-width:700px)
  #search-index-wrapper
    margin: 90px 0 10px 10px

    .search-container
      width: 95%

    .result-right-side
      display: none

@media screen and (min-width:700px)
  #search-index-wrapper
    margin: 90px auto
    padding: 0 10px
