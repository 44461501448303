
a
  text-decoration: none

.title
  font-size: 18px
  font-weight: 500
  margin-bottom: 12px

.card
  display: flex
  padding: 12px
  margin-top: 12px
  height: 88px
  width: 345px
  justify-content: space-between
  background-color: rgb(233, 234, 238, .4)
  border-radius: 6px
  cursor: pointer

  &:hover
    box-shadow: 0px 2px 6px rgb(233, 234, 238)

  .desc
    font-size: 16px

    .title
      color: #2c2c2c
      font-weight: 500

    .content
      color: #565656
      margin-top: 5px

  img
    height: 56px
    width: 56px
